.introText {
  font-size: calc(16px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.3em + (2 - 1.4) * ((100vw - 300px) / (1600 - 300)));
  color: #1e4877;
  margin-top: 20px;
  background-color: #011a4270;
  text-transform: uppercase;
  text-align: center;
  -webkit-text-stroke: calc(
      0.5px + (2 - 0.5) * ((100vw - 300px) / (1600 - 300))
    )
    #fff;
  text-stroke: calc(0.25px + (0.5 - 0.25) * ((100vw - 300px) / (1600 - 300)))
    #fff;
}

.isDisabled-trueLoader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #5397e4;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.isDisabled-falseLoader {
  display: none;
  width: 100%;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #063970 #063970;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
