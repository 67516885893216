body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  box-shadow: none;
  padding: 0px !important;
  border-width: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.waveContent {
  position: relative;
}

.waveContent h4,
.waveContent h5 {
  color: #fff;
  font-size: 3.3rem;
  position: absolute;
  left: 32px;
  top: 42px;
}

.waveContent h5 {
  left: 112px;
  top: 97px;
}

.waveContent h4:nth-child(1),
.waveContent2 h4:nth-child(1),
.waveContent h5:nth-child(3),
.waveContent2 h5:nth-child(3) {
  color: transparent;
  -webkit-text-stroke: 2px #fbfbff;
}

.waveContent h4:nth-child(2),
.waveContent2 h4:nth-child(2),
.waveContent h5:nth-child(4),
.waveContent2 h5:nth-child(4) {
  color: #02029163;
  animation: animate 4s ease-in-out infinite;
}

.waveContent2 h4 {
  color: #fff;
  font-size: 3.3rem;
  position: absolute;
  left: 24vw;
  top: 17vh;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

.logoStyle {
  height: 80px;
  z-index: 9;
}

.logoContainer {
  display: flex;
  position: absolute;
  top: 13%;
  left: -60%;
  z-index: 1000;
}
